import { isEqual } from 'lodash';
import {
  createContext, FC, ReactNode, useContext, useState,
} from 'react';

import { LocationContext, LocationContextDispatchContext } from 'context/LocationContext';
import { SystemContext, SystemDispatchContext } from 'context/SystemContext';

import { getShopSettings, getUserNearestLocation } from 'services/Client';

import { AgeFilterType, OnboardingUseType, OnlineShop } from 'constants/enums';
import { OnboardingInfo } from 'types/onboarding.interface';
import { ShopSettings } from 'types/shopSettings.interface';
import { getStoreDetailsFromStorage } from 'utils/storeUtils';

const OnboardingContext = createContext({
  allowedAge: 0,
  isModalOpen: false,
  locationSettings: {} as ShopSettings,
});

interface OnboardingContextDispatchContextProps {
  closeOnboardingFlow: (setup: OnboardingInfo) => Promise<void>;
  getNearestLocation: () => Promise<OnboardingInfo|null>;
  fetchLocationSettings: (companyId: string) => Promise<ShopSettings|null>;
  closeModal: () => void;
}

const OnboardingContextDispatchContext = createContext({
  closeOnboardingFlow: (setup: OnboardingInfo) => {}, // eslint-disable-line
  getNearestLocation: () => Promise.resolve(null), // eslint-disable-line
  fetchLocationSettings: (companyId: string) => Promise.resolve(null), // eslint-disable-line
  closeModal: () => {}, // eslint-disable-line
} as OnboardingContextDispatchContextProps);

interface OnboardingContextProviderProps {
  children: ReactNode;
  closeModal: () => void;
  isModalOpen: boolean;
}

const OnboardingContextProvider:FC<OnboardingContextProviderProps> = ({ children, closeModal, isModalOpen }) => {
  const { saveShopSettings, saveOnboardingInfo, setSetupCompleted } = useContext(LocationContextDispatchContext);
  const { onboardingInfo: storedOnboardingInfo } = useContext(LocationContext);
  const { shopSettings } = useContext(SystemContext);
  const { refreshClientInfo } = useContext(SystemDispatchContext);

  const [locationSettings, setLocationSettings] = useState<ShopSettings>(shopSettings);

  const allowedAge = locationSettings.filterByAge && locationSettings.filterByAge !== AgeFilterType.None
    ? locationSettings.filterByAge
    : 0;

  const fetchLocationSettings = async (companyId?: string) => {
    try {
      const { data } = await getShopSettings(companyId);
      setLocationSettings(data);
      return data;
    } catch (e) {
      setLocationSettings({});
      return shopSettings;
    }
  };

  const closeOnboardingFlow = async (setup: OnboardingInfo) => {
    const {
      storeId, useType, age, medical,
    } = storedOnboardingInfo;

    if (
      storeId === setup.storeId
      && useType === setup.useType
      && age === setup.age
      && isEqual(medical, setup.medical)
    ) {
      closeModal();
      return;
    }
    const store = getStoreDetailsFromStorage(setup.storeId);
    const clientInfo = await refreshClientInfo(setup.storeId);

    const data = {
      ...setup,
      searchResults: [],
      storeName: store?.name || '',
      storeSlug: clientInfo?.slug || '',
      store,
    };

    saveShopSettings(locationSettings);
    saveOnboardingInfo(data);
    setSetupCompleted(true);
    closeModal();
  };

  const getNearestLocation = async (): Promise<OnboardingInfo|null> => {
    try {
      const { data } = await getUserNearestLocation();

      if (data?.locationInfo?.onlineShop !== OnlineShop.On) {
        return null;
      }

      if (data && data.deliveryMethod && data.useType && data.locationInfo) {
        const { locationInfo } = data || {};
        return {
          state: locationInfo?.address?.state,
          storeId: locationInfo?.companyPin,
          storeName: locationInfo?.name,
          useType: data.useType === 'ADULT' ? OnboardingUseType.is_adult_use : OnboardingUseType.is_medical_use,
          deliveryMethod: data.deliveryMethod,
          companyId: locationInfo?.companyId,
          age: 0,
        };
      }

      return null;
    } catch (e) {
      return null;
    }
  };

  const handleCloseModal = () => {
    closeModal();
    setLocationSettings(shopSettings);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <OnboardingContext.Provider value={{
      allowedAge,
      isModalOpen,
      locationSettings,
    }}
    >
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <OnboardingContextDispatchContext.Provider value={{
        closeOnboardingFlow,
        getNearestLocation,
        closeModal: handleCloseModal,
        fetchLocationSettings,
      }}
      >
        {children}
      </OnboardingContextDispatchContext.Provider>
    </OnboardingContext.Provider>
  );
};

export {
  OnboardingContextProvider,
  OnboardingContext,
  OnboardingContextDispatchContext,
};
