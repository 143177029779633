import { FC, useContext } from 'react';
import { Outlet } from 'react-router-dom';

import KioskInactivityChecker from 'containers/KioskInactivityChecker';
import { CartProvider } from 'context/CartContext';
import { CategoryProvider } from 'context/CategoryContext';
import { LocationContextProvider } from 'context/LocationContext';
import { PaymentProvider } from 'context/PaymentContext';
import { SystemContext } from 'context/SystemContext';

const GlobalProvider:FC = () => {
  const { detailedClientData } = useContext(SystemContext);

  return (
    <LocationContextProvider>
      <CartProvider>
        <CategoryProvider>
          <PaymentProvider>
            <Outlet />
            {detailedClientData?.kioskMode && <KioskInactivityChecker />}
          </PaymentProvider>
        </CategoryProvider>
      </CartProvider>
    </LocationContextProvider>
  );
};

export default GlobalProvider;
