import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import {
  FC,
  useContext, useEffect, useMemo, useState,
} from 'react';

import CustomButton from 'components/CustomButton';
import StoreLocationForm from 'components/StoreLocationForm';
import DialogTitle from 'containers/Onboarding/DialogTitle';
import { DialogContent, DialogActions } from 'containers/Onboarding/index.styled';
import { CartContext } from 'context/CartContext';
import { OnboardingContext } from 'context/OnboardingContext';

import { MedicalIdPromptType, OnboardingSteps } from 'constants/enums';
import { USE_TYPE_OPTIONS } from 'constants/options';
import { OnboardingInfo } from 'types/onboarding.interface';
import { getCompanyLocationsData } from 'utils/storageUtils';

interface DeliveryPickerProps {
  setup: OnboardingInfo;
  updateSettings: (data: Partial<OnboardingInfo>) => void;
  setStep: (step: OnboardingSteps) => void;
  endFlow: () => Promise<void>;
  onClose: () => void;
  kioskMode?: boolean;
}

const DeliveryPicker:FC<DeliveryPickerProps> = ({
  endFlow,
  setup,
  updateSettings,
  onClose,
  setStep,
  kioskMode,
}) => {
  const { statesByUsage, storesByStates } = getCompanyLocationsData() || {};
  const cart = useContext(CartContext);
  const { locationSettings } = useContext(OnboardingContext);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const {
    useType,
    state,
    storeId,
  } = setup;

  const selectedUsage = useType || USE_TYPE_OPTIONS[0].key;
  const stateOptions = useMemo(
    () => (kioskMode ? [] : statesByUsage?.[selectedUsage] || []),
    [selectedUsage, statesByUsage, kioskMode],
  );

  const selectedState = state || stateOptions?.[0]?.key;
  const disableSubmit = !useType || !state || !storeId;

  const storeOptions = useMemo(
    () => (kioskMode ? [] : storesByStates?.[selectedUsage]?.[selectedState] || []),
    [selectedState, storesByStates, kioskMode],
  );

  useEffect(() => {
    const stateOpts = statesByUsage?.[selectedUsage] || [];
    const closestLocation = state || stateOpts?.[0]?.key;
    const storeOpts = storesByStates?.[selectedUsage]?.[closestLocation] || [];
    const closestStore = storeId || storeOpts?.[0]?.key;

    updateSettings({
      useType: selectedUsage,
      state: closestLocation ? closestLocation.toString() : '',
      storeId: closestStore ? closestStore.toString() : '',
    });
  }, []);

  const handleSubmit = async () => {
    if (useType === USE_TYPE_OPTIONS[1].key && locationSettings.medicalIdPrompt === MedicalIdPromptType.AtStart) {
      setStep(OnboardingSteps.MedicalInfo);
    } else {
      setIsSubmitDisabled(true);
      endFlow();
    }
  };

  const handleUseTypeChanges = (fieldName: string, fieldValue: string): void => {
    if (kioskMode) {
      updateSettings({ [fieldName]: fieldValue });
    } else {
      const closestState = statesByUsage?.[fieldValue]?.[0]?.key?.toString() || '';
      const closestStore = storesByStates?.[fieldValue]?.[closestState]?.[0]?.key?.toString() || '';

      updateSettings({
        [fieldName]: fieldValue,
        state: closestState,
        storeId: closestStore,
      });
    }
  };

  const handleStateChanges = (fieldName: string, fieldValue: string): void => {
    const closestStore = storesByStates?.[useType]?.[fieldValue]?.[0]?.key?.toString() || '';

    updateSettings({
      [fieldName]: fieldValue,
      storeId: closestStore,
    });
  };

  const handleChanges = (fieldName: string, fieldValue: string): void => {
    if (fieldName === 'useType') {
      handleUseTypeChanges(fieldName, fieldValue);
    } else if (fieldName === 'state') {
      handleStateChanges(fieldName, fieldValue);
    } else {
      updateSettings({ [fieldName]: fieldValue });
    }
  };

  return (
    <>
      <DialogTitle>
        How and where
        <br />
        do you want to shop?
      </DialogTitle>
      <DialogContent>
        <StoreLocationForm
          data={setup}
          onChange={handleChanges}
          stateOptions={stateOptions}
          storeOptions={storeOptions}
        />

        {!isEmpty(cart?.products) && (
          <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 2 }}>
            Product selection varies by location. If you change your location, some items in your cart may no
            longer be available and will be removed.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          id="button-cancelChangeLocation"
          color="secondary"
          variant="outlined"
          onClick={onClose}
          disabled={disableSubmit}
        >
          Cancel
        </CustomButton>
        <CustomButton
          id="button-submitChangeLocation"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableSubmit || isSubmitDisabled}
        >
          Save
        </CustomButton>
      </DialogActions>
    </>
  );
};

export default DeliveryPicker;
