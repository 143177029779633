import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Divider, Box, Tabs, Tab,
} from '@mui/material';
import {
  FC, SyntheticEvent, useContext, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { Content, Root } from 'components/_CheckoutFooter/index.styled';
import CartTotal from 'components/CartTotal';
import Coupon from 'components/Coupon';
import CustomButton from 'components/CustomButton';
import OrderDisclaimerMessage from 'components/OrderDisclaimerMessage';
import MedicalBalance from 'containers/MedicalBalance';
import { SystemContext } from 'context/SystemContext';

import { getHomeRoute } from 'hooks/useRouting';

import { CartPriceDetails } from 'types/cart.interface';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tab-${index}`,
  };
}

interface CheckoutFooterProps {
  onSubmit: () => void;
  isSummaryLoading: boolean;
  hasSummaryFailed: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
  hasBack?: boolean;
  cartPriceDetails: CartPriceDetails;
  onChangeSummary: (coupon?: string) => void;
  continueLabel: string;
  showMedicalBalance?: boolean;
}

const CheckoutFooter:FC<CheckoutFooterProps> = ({
  onSubmit,
  isSummaryLoading,
  isSubmitting,
  hasSummaryFailed,
  cartPriceDetails,
  disabled,
  hasBack,
  onChangeSummary,
  continueLabel,
  showMedicalBalance = false,
}) => {
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const { shopSettings } = useContext(SystemContext);

  const handleContinueShopping = () => {
    navigate(getHomeRoute());
  };

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Root>
      <Content>
        {showMedicalBalance && (
          <Tabs value={tab} onChange={handleTabChange} aria-label="tab" sx={{ pt: 1, ml: 3 }}>
            <Tab label="Summary" {...a11yProps(1)} />
            <Tab label="Total Balance" {...a11yProps(0)} />
          </Tabs>
        )}

        {tab === 1 && (
          <Box display="flex" position="relative">
            <Box p={3} width="100%">
              <MedicalBalance
                balances={cartPriceDetails.balances}
                checkSummary={onChangeSummary}
              />
            </Box>

            {hasBack && (
              <CustomButton
                id="button-continueShopping"
                variant="outlined"
                sx={{
                  mt: 3, position: 'absolute', left: -250, bottom: 24,
                }}
                onClick={handleContinueShopping}
                icon={<ArrowBackIcon />}
                className="continueShopping"
              >
                Continue Shopping
              </CustomButton>
            )}
          </Box>

        )}

        {tab === 0 && (
          <>
            <Coupon kioskMode onChangeSummary={onChangeSummary} />
            <Divider sx={{ borderBottomWidth: '2px', mx: 3 }} />

            <Box p={3}>
              <CartTotal
                cartPriceDetails={cartPriceDetails}
                isLoading={isSummaryLoading}
                hasFailed={hasSummaryFailed}
                kioskMode
              />

              <CustomButton
                id="button-goToCheckout"
                variant="contained"
                fullWidth
                sx={{ mt: 3 }}
                onClick={onSubmit}
                isLoading={isSummaryLoading || isSubmitting}
                disabled={disabled}
              >
                {continueLabel}
              </CustomButton>

              {hasBack && (
                <CustomButton
                  id="button-continueShopping"
                  variant="outlined"
                  sx={{ mt: 3, position: 'absolute', left: -250 }}
                  onClick={handleContinueShopping}
                  icon={<ArrowBackIcon />}
                  className="continueShopping"
                >
                  Continue Shopping
                </CustomButton>
              )}

              {shopSettings?.orderDisclaimerMessage && (
                <OrderDisclaimerMessage message={shopSettings?.orderDisclaimerMessage} />
              )}
            </Box>
          </>
        )}
      </Content>
    </Root>
  );
};

export default CheckoutFooter;
