import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import { Typography } from '@mui/material';
import { FC, useContext, useMemo } from 'react';

import { ButtonGroup, Button } from 'components/KioskUseTypeSelector/index.styled';
import { LocationContext, LocationContextDispatchContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { OnboardingUseType } from 'constants/enums';
import { USE_TYPE_OPTIONS } from 'constants/options';
import { askForMedicalInfoAtStart } from 'utils/storeUtils';

const KioskUseTypeSelector: FC = () => {
  const { saveOnboardingInfo, setSetupCompleted } = useContext(LocationContextDispatchContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { shopSettings } = useContext(SystemContext);
  const { useType } = onboardingInfo || {};
  const onlyOneType = Boolean(!onboardingInfo?.store?.adultUse || !onboardingInfo?.store?.medicalUse);
  const useTypeLabel = useMemo(() => (
    useType === USE_TYPE_OPTIONS[1].key
      ? USE_TYPE_OPTIONS[1].value
      : USE_TYPE_OPTIONS[0].value
  ), [useType]);

  const handleSelect = (useType: OnboardingUseType) => {
    if (onlyOneType) {
      return;
    }

    if (useType === OnboardingUseType.is_medical_use && askForMedicalInfoAtStart(shopSettings)) {
      setSetupCompleted(false);
    } else {
      saveOnboardingInfo({ ...onboardingInfo, useType });
    }
  };

  return (
    <div>
      <ButtonGroup
        variant="outlined"
        aria-label="Use type button group"
        isRounded={onlyOneType}
      >
        {onboardingInfo?.store?.adultUse && (
          <Button
            onClick={() => handleSelect(USE_TYPE_OPTIONS[0].key)}
            active={useType === USE_TYPE_OPTIONS[0].key}
          >
            <SelfImprovementIcon className="useType" sx={{ width: '40px', height: '40px' }} />
          </Button>
        )}
        {onboardingInfo?.store?.medicalUse && (
          <Button
            onClick={() => handleSelect(USE_TYPE_OPTIONS[1].key)}
            active={useType === USE_TYPE_OPTIONS[1].key}
          >
            <MedicalServicesOutlinedIcon className="useType" sx={{ width: '40px', height: '40px' }} />
          </Button>
        )}
      </ButtonGroup>
      <Typography textAlign="center" className="headerBackground" mt={1}>
        {useTypeLabel}
      </Typography>
    </div>
  );
};

export default KioskUseTypeSelector;
