import MuiButton from '@mui/material/Button';
import MuiButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';

import { KIOSK_SIDEBAR_WEIGHT } from 'constants/general';
import { getContrastColorForHeaderBackground } from 'utils/clientUtils';

export const ButtonGroup = styled(
  MuiButtonGroup,
  { shouldForwardProp: (props) => props !== 'isRounded' },
)<{ isRounded: boolean }>(({ isRounded }) => ({
  height: '50px',
  width: `calc(${KIOSK_SIDEBAR_WEIGHT}px - 32px)`,
  ...(isRounded ? {
    '& .MuiButtonGroup-grouped': {
      borderRadius: '25px',
    },
  } : {}),

  '& .MuiButtonGroup-firstButton': {
    borderTopLeftRadius: '25px',
    borderBottomLeftRadius: '25px',
  },

  '& .MuiButtonGroup-lastButton': {
    borderTopRightRadius: '25px',
    borderBottomRightRadius: '25px',
  },
}));

export const Button = styled(
  MuiButton,
  { shouldForwardProp: (props) => props !== 'active' },
)<{ active: boolean }>(({ theme, active }) => {
  const { contrastColor } = getContrastColorForHeaderBackground(theme);

  return ({
    flex: 1,
    border: `2px solid ${contrastColor}`,
    backgroundColor: active ? contrastColor : theme.palette.headerBackground.main,

    '&:hover': {
      border: `2px solid ${contrastColor}`,
    },

    '& svg': {
      fill: active ? theme.palette.headerBackground.main : contrastColor,
    },
  });
});
