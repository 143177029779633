import { alpha, styled } from '@mui/material/styles';

export const Chip = styled('div')<{ isActive?: boolean; disabled?: boolean }>(({ theme, isActive, disabled }) => ({
  paddingLeft: '4px',
  paddingRight: '4px',
  borderRadius: '40px',
  width: '100%',
  padding: theme.spacing(0.5, 1, 0.5, 1),
  border: `3px solid ${disabled ? theme.palette.text.secondary : theme.palette.primary.main}`,
  cursor: 'pointer',
  backgroundColor: isActive ? alpha(theme.palette.primary.main, 0.7) : theme.palette.common.white,
  color: isActive ? theme.palette.common.white : theme.palette.text.secondary,
  '& .label': {
    textAlign: 'center',
    flexGrow: 1,
  },
  '& .title': {
    // eslint-disable-next-line no-nested-ternary
    color: isActive
      ? theme.palette.common.white
      : disabled ? theme.palette.text.secondary : theme.palette.primary.main,
  },
  '& .MuiSvgIcon-root': {
    visibility: isActive ? 'visible' : 'hidden',
  },
  '& .useType': {
    // eslint-disable-next-line no-nested-ternary
    color: isActive
      ? theme.palette.common.white
      : disabled ? theme.palette.text.secondary : theme.palette.primary.main,
    visibility: 'visible',
    marginLeft: theme.spacing(1),
  },
}));
