import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Dialog, DialogActions, DialogContent, IconButton,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import { LocationContext, LocationContextDispatchContext } from 'context/LocationContext';
import { SystemDispatchContext } from 'context/SystemContext';
import { UserContext, UserDispatchContext } from 'context/UserContext';

import { HOME_ROUTE } from 'constants/clientRoutes';

const ResetKiosk = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { renewKioskUser } = useContext(UserDispatchContext);
  const { setShowStartNewOrder } = useContext(SystemDispatchContext);
  const { saveOnboardingInfo } = useContext(LocationContextDispatchContext);
  const { user } = useContext(UserContext);
  const { onboardingInfo } = useContext(LocationContext);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const handleReset = async () => {
    handleClose();

    if (!user?.isAnonymous) {
      await renewKioskUser();
    }

    if (onboardingInfo.medical) {
      saveOnboardingInfo({ ...onboardingInfo, medical: undefined });
    }

    setShowStartNewOrder(true);
    navigate(`${HOME_ROUTE}?category=all`, { replace: true });
  };

  return (
    <>
      <IconButton onClick={handleOpen} className="headerBackground">
        <RefreshIcon />
      </IconButton>
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-describedby="reset-start-new-order"
          PaperProps={{
            sx: {
              borderRadius: 4, py: 3, px: 1,
            },
          }}
        >
          <DialogContent>
            Please take a moment: You’re about to reset the entire process and start fresh.
            If you’re sure, just confirm to continue.
          </DialogContent>
          <DialogActions sx={{ p: '0 24px' }}>
            <CustomButton id="button-cancelReset" variant="outlined" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton id="button-submitReset" variant="contained" onClick={handleReset}>
              Confirm
            </CustomButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ResetKiosk;
